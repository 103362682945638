// Next.js API route support: https://nextjs.org/docs/api-routes/introduction

const API_URL = process.env.NEXT_PUBLIC_WP_API_URL || "https://backend.thefinefinance.com/graphql/";

export interface WPImage {
  file: string
  sourceUrl?: string
  height: number
  width: number
}

export interface PostExcerpt {
  id: string
  date: string // creation date
  title: string
  excerpt: string
  slug: string
  categories: {
    nodes: {
      slug: string
    }[]
  }
  featuredImage: {
    node: {
      title: string
      altText: string
      // sourceUrl: string
      mediaDetails: WPImage
    }
  }
}

export interface Posts {
  edges: { node: PostExcerpt }[]
}
export interface PostsSlugs {
  edges: {
    node: {
      slug: string
      categories: {
        nodes: {
          slug: string
        }[]
      }
    }
  }[]
}
export interface Post extends PostExcerpt {
  content: string
  galleryIds: string[]
  modified: string
  author: {
    node: {
      firstName: string
      lastName: string
    }
  }
  metaData: {
    key: string
    value: string
  }[]
}


export interface GalleryItem {
  databaseId: number
  title: string
  caption: string
  mediaDetails: WPImage
}


/** FETCH WP GRAPHQL API */
export async function fetchWPAPI(query: any, { variables }: any = {}) {
  // Set up some headers to tell the fetch call
  // that this is an application/json type
  const headers = { 'Content-Type': 'application/json' };

  // build out the fetch() call using the API_URL
  // environment variable pulled in at the start
  // Note the merging of the query and variables
  // console.log(JSON.stringify({ query, variables }))
  const res = await fetch(API_URL, {
    method: 'POST',
    headers,
    body: JSON.stringify({ query, variables })
  });

  // error handling work
  const json = await res.json();
  if (json.errors) {
    console.log(json.errors);
    console.log('error details', query, variables);
    throw new Error('Failed to fetch API');
  }
  return json.data;
}

interface Params {
  [key: string]: string
}
/** FETCH STANDARD API */
export async function fetchAPI(url: string, params: Params, method: "GET" | "POST" = "GET") {
  // Set up some headers to tell the fetch call
  // that this is an application/json type
  const headers = { 'Content-Type': 'application/json' };

  // build out the fetch() call using the API_URL
  // environment variable pulled in at the start
  // Note the merging of the query and variables
  // console.log(JSON.stringify({ query, variables }))

  const urlObj = new URL(url)
  Object.keys(params).forEach(key => urlObj.searchParams.append(key, params[key]))
  const res = await fetch(urlObj.toString(), {
    method: 'GET',
    headers
  });

  // error handling work
  const json = await res.json();
  if (json.errors) {
    throw new Error('Failed to fetch API');
  }
  return json;
}


/** Get all homepage posts (only finance) */
export async function getAllPosts(preview: boolean): Promise<Posts> {

  const data = await fetchWPAPI(
    `
    query AllPosts {
      posts(first: 18, where: { categoryName: "finance", orderby: { field: DATE, order: DESC}}) {
        edges {
          node {
            id
            date
            title
            slug
            categories {
              nodes {
                slug
              }
            }
            featuredImage {
              node {
                title
                altText
                mediaDetails {
                  file
                  height
                  width
                }
              }
            }
          }
        }
      }
    }
    `
  );
  return data?.posts;
}

/** Get last posts */
export async function getLastPosts(): Promise<Posts> {

  const data = await fetchWPAPI(
    `
    query LastPosts {
      posts(last: 10, where: { orderby: { field: DATE, order: DESC}}) {
        edges {
          node {
            id
            date
            title
            slug
            categories {
              nodes {
                slug
              }
            }
            featuredImage {
              node {
                title
                altText
                mediaDetails {
                  file
                  height
                  width
                }
              }
            }
          }
        }
      }
    }
    `
  );
  return data?.posts;
}

export async function getAllPostsWithSlug(): Promise<PostsSlugs> {
  const data = await fetchWPAPI(
    `
    query AllPostsWithSlug {
      posts(first: ${process.env.NUMBER_OF_POSTS_TO_PREFETCH || 1000}, where: { orderby: { field: DATE, order: DESC}}) {
        edges {
          node {
            slug
            categories {
              nodes {
                slug
              }
            }
          }
        }
      }
    }
  `);
  return data?.posts;
}



export async function getPost(slug: string): Promise<Post> {
  const data = await fetchWPAPI(
    `
    fragment PostFields on Post {
      id
      content
      title
      excerpt
      slug
      date
      modified
      galleryIds
      categories {
        nodes {
          name
          slug
        }
      }
      metaData(names: ["diapo_template_mai_2021", "diapo_monetisation_mobile"]) {
        key
        value
      }
      author {
        node {
          firstName
          lastName
        }
      }
      featuredImage {
        node {
          title
          altText
          mediaDetails {
            file
            height
            width
          }
        }
      }
    }
    query PostBySlug($id: ID!, $idType: PostIdType!) {
      post(id: $id, idType: $idType) {
        ...PostFields
      }
    }
  `,
    {
      variables: {
        id: slug,
        idType: 'SLUG'
      }
    }
  )
  return data.post
}

/** Get a gallery item. Called from pages/api/slide to cache in CF */
export async function getGalleryItem(id: string): Promise<GalleryItem> {
  const data = await fetchAPI(`${process.env.NEXT_PUBLIC_SITE_URL}api/slide`, { id })
  return data
}

/** Get a gallery item, directly from graphql */
export async function getGalleryItemFromWP(id: string): Promise<GalleryItem> {
  const data = await fetchWPAPI(
    `
      query MediaItemById($id: ID!) {
        mediaItem(id: $id, idType: DATABASE_ID) {
          databaseId
          title
          caption
          mediaDetails {
            file
            height
            width  
          }
        }
      }
    `,
    {
      variables: {
        id: id
      }
    }
  )
  return data.mediaItem
}


export async function getPreviewPost(id: string[] | string, idType = 'DATABASE_ID') {
  const data = await fetchWPAPI(
    `
    query PreviewPost($id: ID!, $idType: PostIdType!) {
      post(id: $id, idType: $idType) {
        databaseId
        slug
        status
      }
    }`,
    {
      variables: { id, idType },
    }
  )
  return data.post
}