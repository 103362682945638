import { FunctionComponent, useEffect, useState } from "react"
import LazyLoad from "react-lazyload"
import { SLOTS, SlotName } from "../config/ads"
import useIsMobile from "../lib/mobile"
import { useStore } from "../store"

interface AdProps {
  /** Ad position */
  slotId: SlotName
  /** Ad div id */
  divId?: string
  /** Do we refresh and display ad already of should be refresh later ? */
  shouldRefresh?: boolean
  lazy?: boolean
  sticky?: boolean
  fixed?: boolean
}

export const ConditionalWrapper = ({
  condition,
  wrapper,
  children
}: {
  condition: boolean
  wrapper: (child: JSX.Element) => JSX.Element
  children: JSX.Element
}) => (condition ? wrapper(children) : children)

const Ad: FunctionComponent<AdProps> = ({ slotId, divId, shouldRefresh, lazy = false, sticky = false, fixed = false }) => {

  const enabled = true

  const { adStore } = useStore()
  const isMobile = useIsMobile()

  useEffect(() => {
    return () => {
      // on unmount
      adStore.resetSlotDate(divId || slotId)
    }
  }, []);

  useEffect(() => {
    if (enabled) {
      // define slot
      adStore.addSlot(slotId, divId as string)
      if (shouldRefresh) {
        adStore.refreshAds([divId as string])
      }

      // display slot
      window.googletag.cmd.push(function () {
        window.googletag.display(divId)
      })
    }
  }, [divId])



  // handle sticky behavior
  const [isStickyActive, setStickyActive] = useState(false);

  useEffect(() => {
    if (sticky)
      window.addEventListener("scroll", isSticky);
    return () => {
      sticky && window.removeEventListener("scroll", isSticky);
    };
  }, []);

  const getOffset = (element: HTMLElement) => {
    const elementRect = element?.getBoundingClientRect()
    return elementRect?.top
  }

  const isSticky = () => {
    const heightToHide = getOffset(document.getElementById(`${divId}_wrapper`)!)

    if (heightToHide < 0) {
      setStickyActive(true);
    }
    else {
      setStickyActive(false)
    }
  };

  if (!divId) divId = slotId
  const slot = SLOTS.find((slot) => slot.name === slotId)
  if (!slot) {
    return <div>Adslot {slotId} could not be found.</div>
  }

  // const isMobile = useIsTouchDevice()

  let placeholder = isMobile ? slot.placeholder.mobile : slot.placeholder.desktop
  if (!placeholder) {
    placeholder = [0, 0]
  }




  return (
    <div id={`${divId}_wrapper`}
      className={`flex justify-center items-center ad-item 
          ${slot.placeholder.mobile && `adsize_${slot.placeholder.mobile[0]}_${slot.placeholder.mobile[1]}`} 
          ${slot.placeholder.desktop && `desktop_adsize_${slot.placeholder.desktop[0]}_${slot.placeholder.desktop[1]}`}
          ${!isStickyActive ? 'ad-placeholder' : null} ${fixed ? 'fixed bottom-0 left-0 right-0 z-10' : 'relative'}`}
      style={{ minWidth: `${placeholder![0]}px`, minHeight: `${placeholder![1]}px` }}>
      {/* second wrapper for sticky behavior */}
      <ConditionalWrapper condition={sticky} wrapper={(children) => <div className={`flex justify-center items-center ad-item ad-placeholder 
          ${slot.placeholder.mobile && `adsize_${slot.placeholder.mobile[0]}_${slot.placeholder.mobile[1]}`} 
          ${slot.placeholder.desktop && `desktop_adsize_${slot.placeholder.desktop[0]}_${slot.placeholder.desktop[1]}`}
          ${isStickyActive ? 'fixed top-0 z-10' : 'relative'}`}
        style={{ minWidth: `${placeholder![0]}px`, minHeight: `${placeholder![1]}px` }}>
        {children}</div>}>
        <ConditionalWrapper condition={lazy} wrapper={(children) => <LazyLoad height={200}>{children}</LazyLoad>}>
          <div id={divId} className={slotId}></div>
        </ConditionalWrapper>
      </ConditionalWrapper>
    </div>
  )
}
export default Ad
