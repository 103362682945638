import { GetStaticPaths, GetStaticProps, NextPage } from "next"
import Head from "next/head"
import { useRouter } from "next/router"
import { GalleryItem, getAllPostsWithSlug, getGalleryItem, getGalleryItemFromWP, getLastPosts, getPost, Post, PostExcerpt, Posts } from "../lib/api"
import { ParsedUrlQuery } from "querystring"
import Layout from "../components/layout"
import { SWRConfig, unstable_serialize } from "swr"
import useSWRImmutable from "swr/immutable"
import InfinitePage from "../components/template/infinitepage"
import SlidePage from "../components/template/slidepage"
import { getCategoryFromPost } from "../lib/cms"

type Props = {
  fallback: {
    post: Post
    slide: GalleryItem | null
    lastPosts: Posts
  }
}

export const Page = () => {
  const router = useRouter()
  const slug = router.query.slug as string

  const { data: post } = useSWRImmutable("post", () => getPost(slug))


  if ((!router.isFallback && !post?.title) || !post) {
    return <p>Erreur</p>
  }

  const Template = (post.metaData.find((meta) => meta.key == "diapo_template_mai_2021")?.value === "on"
    || post.metaData.find((meta) => meta.key == "diapo_monetisation_mobile")?.value === "on")
    ? SlidePage : InfinitePage

  // const format = isMobile ? "rw_medium" : "medium_large"
  // const image = data.featuredImage.node.mediaDetails.sizes.find((size) => size.name == "medium_large")
  const created = post.date ? new Date(post.date) : null
  const modified = post.modified ? new Date(post.modified) : null

  return (
    <Layout>
      {router.isFallback ? (
        <p>Chargement...</p>
      ) : (
        <>
          {/* <PreloadedSlots /> */}

          <Head>
            <title>{post.title} - The Fine Finance</title>
            <meta name="author" content={`${post.author.node.firstName} ${post.author.node.lastName}`} />
          </Head>

          <main>

            <div className="bg-gray-100 p-4">
              <h1>{post.title}</h1>
              <div dangerouslySetInnerHTML={{ __html: post.excerpt }}></div>

              <div className="flex justify-center gap-2 text-xs font-bold text-gray-500 text-center">
                {post.author.node.firstName && <span className="border-r-2 px-2">By {post.author.node.firstName} {post.author.node.lastName}</span>}
                {created && <span className="border-r-2 px-2">Published on {created?.toLocaleDateString()}</span>}
                {modified && <span>Last modified on {modified?.toLocaleDateString()}</span>}
              </div>
            </div>

            <Template post={post} />
          </main>
        </>
      )}
    </Layout>
  )
}

const PostPage: NextPage<Props> = ({ fallback }) => {
  const router = useRouter()
  const slug = router.query.slug as string
  
  if (!slug) return <p></p>
  // if (!fallback.slide) return <p>This content does not have any slide.</p>
  return (
    // provide default prefetched values with 'fallback'
    <SWRConfig value={{ fallback: { [unstable_serialize(["slide", "" + fallback.slide?.databaseId])]: fallback.slide, post: fallback.post, lastPosts: fallback.lastPosts } }}>
      <Page />
    </SWRConfig>
  )
}

export const getStaticPaths: GetStaticPaths = async (context) => {
  const allPosts = await getAllPostsWithSlug()
  return {
    paths: allPosts.edges.map(({ node }) => `/${getCategoryFromPost(node as PostExcerpt)}/${node.slug}`) || [],
    fallback: true
  }
}

interface Params extends ParsedUrlQuery {
  slug: string
}

export const getStaticProps: GetStaticProps<Props, Params> = async (context) => {
  const slug = context.params!.slug

  let postSlug = null
  let category = null
  if(slug.length === 1)
    postSlug = slug[0]
  else{
    category = slug[0]
    postSlug = slug[1]
  }

  const post = await getPost(postSlug)

  if (!post) return { notFound: true }

  // get last posts
  const lastPosts = await getLastPosts()

  // get first element of gallery
  const firstId = post.galleryIds[0]
  // console.log(post.galleryIds)
  const slide = firstId ? await getGalleryItemFromWP(firstId) : null

  return {
    props: {
      fallback: {
        post,
        slide,
        lastPosts
      }
    },
    revalidate: 3600
  }
}

export default PostPage
