import { FunctionComponent } from "react"
import Link from 'next/link'
import { PostExcerpt } from "../lib/api"
import Image from "next/image"
import { getCategoryFromPost } from "../lib/cms"
const IMAGE_BASE_URL = process.env.NEXT_PUBLIC_WP_IMAGE_BASE_URL || "https://backend.thefinefinance.com/wp-content/uploads/"

interface SmallPostProps {
    post: PostExcerpt
}

const SmallPost: FunctionComponent<SmallPostProps> = ({ post }) => {

    const image = post.featuredImage.node.mediaDetails.file
    const category = getCategoryFromPost(post)
    return (
        <section
            className="shadow-lg group container  rounded-md bg-white  max-w-sm flex justify-center items-center mx-auto content-div transform hover:scale-105 transition"
            style={{ maxWidth: '300px' }}>
            <Link href={`/${category}/${post.slug}`} passHref>
                <a title="Read more" className="no-underline">
                    {image &&
                        <div className="max-h-[150px] overflow-hidden">
                            <figure>
                                <Image
                                    src={`${IMAGE_BASE_URL}/${image}`}
                                    alt={post.featuredImage.node.altText}
                                    title={post.featuredImage.node.title}
                                    width={300}
                                    height={200}
                                    objectFit="cover"
                                    className="w-full rounded-t-md image-cover max-h-52" />
                            </figure>
                        </div>
                    }
                    <h2 className="block text-lg text-gray-800 font-bold tracking-wide p-2 h-[130px] text-center overflow-hidden">{post.title}</h2>
                </a>
            </Link>
        </section>
    )
}
export default SmallPost
