import { FunctionComponent, useEffect, useState } from "react"
import { ArticleTemplateProps, SlideProps } from "."
import Ad from "../ad"
import useSWRImmutable from "swr/immutable"

import Image from "next/image"
import { getGalleryItem } from "../../lib/api"
import LazyLoad from "react-lazyload"
import { useRefreshAd, useSlidePrefetch } from "../../lib/hooks"
import { useStore } from "../../store"
import { SlideImagePlaceholder, SlideTextPlaceholder } from "../slidePlaceholder"
import useIsMobile from "../../lib/mobile"
import RichText from "../richtext"
import { _getImageFromPost, _getImageFromSlide } from "./slidepage"
import { SlotName } from "../../config/ads"
import LastPosts from "../lastPosts"

// example : https://www.thefinefinance.com/famous-couples-then-vs-now

interface InfiniteSlideProps extends SlideProps {
  setLastSlideId(slideId: string): void
  slideIndex: number
}

const Slide: FunctionComponent<InfiniteSlideProps> = ({ slideId, nextSlideId, slideIndex, setLastSlideId }) => {
  const { adStore } = useStore()
  const { data: slide, error } = useSWRImmutable(["slide", slideId], () => getGalleryItem(slideId), {
    dedupingInterval: 60000
  })
  const isLoading = !slide && !slide

  const isMobile = useIsMobile()

  let slot1 = "small_2"
  let slot2 = "small_3"
  if (isMobile) {
    if (slideIndex == 0) {
      slot1 = "small_4"
      slot2 = "small_5"
    }
    if (slideIndex == 1) {
      slot1 = "small_6"
      slot2 = "long_7"
    }
    else if (slideIndex > 2) {
      slot1 = "small_8"
      slot2 = "long_9"
    }
  }

  useRefreshAd([`slot1_${slideId}`, `slot2_${slideId}`, "mpu_haut", "mpu_bas"], [isMobile, slideId])

  useEffect(() => {
    if (isLoading === false && !error) setLastSlideId(slideId)
  }, [isLoading, slideId])

  useSlidePrefetch(nextSlideId)

  // useEffect(() => {
  //   // adStore.refreshAds([`slot1_${slideId}`, `slot2_${slideId}`])
  //   adStore.refreshAds([`slot1_${slideId}`])
  // }, [])

  const image = _getImageFromSlide(slide)

  return (
    <div>

      {/* SLIDE {slideId} - {nextSlideId} */}


      <div className="p-4 bg-gray-100 my-2">
        <div className="my-4">
          {isLoading ? (
            <SlideTextPlaceholder />
          ) :
            <>
              <h2>{slide.title}</h2>
              <RichText text={slide.caption} />
            </>
          }
        </div>

        {isLoading ? (
          <SlideImagePlaceholder />
        ) : (
          <div className="my-4">
            {image && (
              <Image
                src={image.sourceUrl!}
                layout="responsive"
                width={image.width}
                height={image.height}
                alt={slide.title || ""}
              />
            )}
          </div>
        )}
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 my-2">
        <Ad slotId={slot1 as SlotName} divId={`slot1_${slideId}`} />
        <Ad slotId={slot2 as SlotName} divId={`slot2_${slideId}`} />
      </div>
    </div>
  )
}

/** A template to render an "infinite scroll" slides, loading next slide when user scroll down */
const InfinitePage: FunctionComponent<ArticleTemplateProps> = ({ post }) => {
  const { adStore } = useStore()
  // store current last loaded slide
  const [lastSlideId, setLastSlideId] = useState<string>(post?.galleryIds[0])
  const isMobile = useIsMobile()
  useRefreshAd(["sticky", "fixed_banner", "masthead_haut", "mpu_haut", "mpu_bas", "post1", "post2"], [isMobile])

  // find last loaded slide in list of slides
  const currentSlideIndex = post.galleryIds.indexOf(lastSlideId)
  // display only current slide + next slide
  const slidesIds = post.galleryIds.slice(1, currentSlideIndex + 2)

  // show outbrain when all slides have been displayed
  useEffect(() => {
    if (currentSlideIndex === post.galleryIds.length - 1) {
      adStore.enableOutbrain()
    }
  }, [currentSlideIndex])

  const image = _getImageFromPost(post)

  return (
    <div>
      <article>
        {/* <Ad slotId="slot1" /> */}

        <div className="w-full justify-center align-middle my-4">
          {isMobile === false && <Ad slotId="masthead_haut" />}
          {isMobile && <Ad slotId="sticky" sticky />}
          {isMobile && <Ad slotId="fixed_banner" fixed />}
          {isMobile && <Ad slotId="small_1" />}
        </div>

        <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
          <div className="col-span-12 lg:col-span-9 xl:col-span-6">
            <div id="avantis-wrapper"> </div>


            <div className="grid grid-cols-1 md:grid-cols-2 my-4">
              <Ad slotId="small_2" divId={`post1`} />
              <Ad slotId="small_3" divId={`post2`} />
            </div>

            {/* First slide is prerendered by server */}
            <Slide
              slideId={post?.galleryIds[0]}
              nextSlideId={post.galleryIds.length >= 2 ? post?.galleryIds[1] : null}
              setLastSlideId={() => setLastSlideId(post?.galleryIds[0])}
              slideIndex={0}
            ></Slide>

            {slidesIds.map((slideId, index) => (
              <div key={slideId} className="min-h-[400px]">
                <LazyLoad key={slideId} once offset={1000}>
                  <Slide
                    slideId={slideId}
                    nextSlideId={post.galleryIds.length > index + 1 ? post.galleryIds[index + 2] : null}
                    setLastSlideId={() => setLastSlideId(slideId)}
                    slideIndex={index + 1}
                  ></Slide>
                </LazyLoad>
              </div>
            ))}

            <div className="p-4 bg-gray-100 my-2">
              {image && (
                <div>
                  <Image
                    src={image.sourceUrl!}
                    layout="responsive"
                    width={image.width}
                    height={image.height}
                    alt={post.title || ""}
                    priority
                  />
                </div>
              )}
              <RichText text={post.content} />
            </div>

            <LastPosts />

          </div>

          <div className="lg:col-span-3 xl:col-span-6">
            <div className="grid grid-cols-12 lg:sticky lg:top-[90px]">
              <aside className="col-span-12 xl:col-span-6">
                {isMobile === false && <Ad slotId="mpu_haut" />}
              </aside>
              <aside className="col-span-12 xl:col-span-6">
                {isMobile === false && <Ad slotId="mpu_bas" />}
              </aside>
            </div>
          </div>

        </div>


      </article>
    </div>
  )
}
export default InfinitePage
