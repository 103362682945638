import { FunctionComponent } from "react"
import Link from 'next/link'
import useSWRImmutable from "swr/immutable"
import { getLastPosts } from "../lib/api"
import SmallPost from "./smallPost"


const LastPosts: FunctionComponent = () => {
    const { data: lastPosts } = useSWRImmutable("lastPosts", () => getLastPosts())

    return (
        <>
            {lastPosts &&
                <footer className="last-posts">
                    <h2 className="mb-4">You&apos;ll also like...</h2>
                    <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4">


                        {lastPosts.edges.slice(0, 6).map(post =>
                            <SmallPost post={post.node} key={post.node.id} />
                        )}
                    </div>
                </footer>
            }
        </>
    )
}
export default LastPosts
