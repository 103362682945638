import { FunctionComponent, useEffect, useRef, useState } from "react"
import { ArticleTemplateProps } from "."
import Ad from "../ad"
import useSWRImmutable from "swr/immutable"

import Image from "next/image"
import { GalleryItem, getGalleryItem, getLastPosts, Post, WPImage } from "../../lib/api"
import LazyLoad from "react-lazyload"
import { useRefreshAd, useSlidePrefetch } from "../../lib/hooks"
import { useStore } from "../../store"
import { SlideImagePlaceholder, SlideTextPlaceholder } from "../slidePlaceholder"
import useIsMobile from "../../lib/mobile"
import RichText from "../richtext"
import Callback from "../callback"
import { useRouter } from "next/router"
import { getCategoryFromPost } from "../../lib/cms"
const IMAGE_BASE_URL = process.env.NEXT_PUBLIC_WP_IMAGE_BASE_URL || "https://backend.thefinefinance.com/wp-content/uploads/";

interface SlideProps {
  slideId: string
  // slide?: GalleryItem
  loading: boolean
  title?: string
  text?: string
  image?: WPImage
  hidden?: boolean
  slideIndex?: number
  slideCount?: number
  ads?: boolean
}


export const _getImageFromSlide = (slide: GalleryItem | undefined): WPImage | undefined => {
  const image = slide?.mediaDetails
  if (image)
    image.sourceUrl = `${IMAGE_BASE_URL}${image.file}`
  return image
}
export const _getImageFromPost = (post: Post | undefined): WPImage | undefined => {
  const image = post?.featuredImage.node.mediaDetails
  if (image)
    image.sourceUrl = `${IMAGE_BASE_URL}${image.file}`
  return image
}


const Slide: FunctionComponent<SlideProps> = ({ slideId, loading, image, title, text, hidden = false, slideIndex = null, slideCount = null, ads = true }) => {
  const isMobile = useIsMobile()

  useRefreshAd([`slot1_${slideId}`, `slot2_${slideId}`, `slot3_${slideId}`, `slot4_${slideId}`, 'masthead_haut'], [slideId, isMobile])

  return (
    <div style={{ display: hidden ? 'none' : '' }}>

      {ads &&
        <div className="grid grid-cols-1 lg:grid-cols-2">
          <Ad slotId="small_1" divId={`slot1_${slideId}`} />
          {isMobile === false && <Ad slotId="small_2" divId={`slot2_${slideId}`} />}
        </div>
      }

      <div className="p-4 bg-gray-100 my-2">
        <div className="">
          {loading ? (
            <SlideTextPlaceholder />
          ) :
            <>
              {slideIndex !== null && slideCount &&
                <p className="font-bold text-center text-indigo-900">{slideIndex + 1} / {slideCount}</p>
              }
              <h2>{title}</h2>
              {text && <RichText text={text} />}
            </>
          }
        </div>

        {loading ? (
          <SlideImagePlaceholder />
        ) : (
          <div className="">
            {image && (
              <Image
                src={image.sourceUrl!}
                layout="responsive"
                width={image.width}
                height={image.height}
                alt={title || ""}
                priority={true}
              />
            )}
          </div>
        )}
      </div>

      {ads &&
        <div className="grid grid-cols-1 lg:grid-cols-2">
          {isMobile && <Ad slotId="small_2" divId={`slot2_${slideId}`} />}
          {isMobile === false && <Ad slotId="small_3" divId={`slot3_${slideId}`} />}
          {isMobile === false && <Ad slotId="small_4" divId={`slot4_${slideId}`} />}
        </div>
      }
    </div>
  )
}

/** A template to render one slide at a time with a button to show next slide */
const SlidePage: FunctionComponent<ArticleTemplateProps> = ({ post }) => {

  const { adStore } = useStore()
  const router = useRouter()
  // set a wait delay of 3s before a user can click on next
  const [isWaiting, setIsWaiting] = useState(false)

  // append "last" slide to slides ids ("last" is in fact the post content)
  const slideIds = [...post?.galleryIds]
  slideIds.push('last')

  // get default slide from url query params
  let startingSlideIndex = router.query.slide ? parseInt(router.query.slide as string, 10) - 1 : 0
  if (startingSlideIndex > slideIds.length)
    startingSlideIndex = 0
  const startingSlideId = slideIds[startingSlideIndex]

  // store current last loaded slide
  const [currentSlideId, setCurrentSlideId] = useState<string>(startingSlideId)

  const { data: slide, error } = useSWRImmutable(["slide", currentSlideId], () => {
    if (currentSlideId != "last")
      return getGalleryItem(currentSlideId)
  }, {
    dedupingInterval: 60000
  })
  const isLoading = !slide && !error && currentSlideId != "last"
  const isMobile = useIsMobile()

  useRefreshAd(["sticky", "fixed_banner", "masthead_haut", "mpu_haut", "mpu_bas", "post1", "post2"], [isMobile])
  const anchor = useRef<HTMLAnchorElement>(null)

  // find last loaded slide in list of slides
  const currentSlideIndex = slideIds.indexOf(currentSlideId)
  // display only current slide + next slide (hidden)
  const nextSlideId = currentSlideIndex < slideIds.length ? slideIds[currentSlideIndex + 1] : null

  // preload n+2 slide
  const nextNextSlideId = currentSlideIndex + 1 < slideIds.length ? slideIds[currentSlideIndex + 2] : null
  useSlidePrefetch(nextNextSlideId)


  // update url when changing slide
  useEffect(() => {
    if (router.isReady) {
      router.replace({
        pathname: router.pathname,
        query: { ...router.query, slide: currentSlideIndex + 1 },
      }, undefined, { shallow: true })
    }
  }, [router.isReady, currentSlideIndex])

  function _setSlide(slideId: string) {
    /** set current slide (based on id and not index) */
    setCurrentSlideId(slideId)
    // setTimeout to make sure rerender has occured
    setTimeout(() => document.getElementById("slide-anchor-start")?.scrollIntoView({ behavior: 'smooth' }), 1)
    setTimeout(() => setIsWaiting(false), 2000)
  }

  const { data: lastPosts } = useSWRImmutable("lastPosts", () => getLastPosts())

  const loadNextSlide = (e: React.MouseEvent<HTMLButtonElement>) => {
    setIsWaiting(true)
    e.preventDefault()
    if (nextSlideId && !isLoading) {
      _setSlide(nextSlideId)
    }
    else if (lastPosts) {
      // redirect to another post
      setIsWaiting(false)
      const posts = lastPosts.edges.filter(lastPost => lastPost.node.slug !== post.slug)
      const randomPost = posts[Math.floor(Math.random() * posts.length)]
      const category = getCategoryFromPost(randomPost.node)
      // it would be better to use next router to handle this but it's not working properly (redirect to last slide for whatever reason)
      window.location.href = `/${category}/${randomPost.node.slug}`
    }
    window.dataLayer && window.dataLayer.push({ event: 'viewed_diapo', label: currentSlideId })
  }
  const loadPreviousSlide = (e: React.MouseEvent<HTMLButtonElement>) => {
    setIsWaiting(true)
    e.preventDefault()
    if (currentSlideIndex >= 1 && !isLoading) {
      const previousSlideId = slideIds[currentSlideIndex - 1]
      _setSlide(previousSlideId)
    }
  }

  return (
    <div>
      <article>
        {/* <Ad slotId="slot1" /> */}

        <span ref={anchor} id="slide-anchor-start"></span>

        <div className="w-full justify-center align-middle my-4">
          {isMobile === false && <Ad slotId="masthead_haut" />}
          {isMobile && <Ad slotId="sticky" sticky />}
          {isMobile && <Ad slotId="fixed_banner" fixed />}
        </div>

        <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
          <div className="col-span-12 lg:col-span-9 xl:col-span-6">
            {/* <div id="avantis-wrapper"> </div> */}

            {currentSlideId != "last" ?
              <>
                <Slide
                  key={currentSlideId}
                  image={_getImageFromSlide(slide)}
                  title={slide?.title}
                  text={slide?.caption}
                  loading={!slide}
                  slideId={currentSlideId}
                  slideIndex={currentSlideIndex}
                  slideCount={slideIds.length}
                ></Slide>

                {/* preload next slide ads */}
                {nextSlideId &&
                  <Slide
                    key={nextSlideId}
                    loading={!slide}
                    slideId={nextSlideId}
                    hidden={true}
                    slideIndex={currentSlideIndex + 1}
                    slideCount={slideIds.length}
                  ></Slide>
                }
              </>

              :

              <Slide
                key="mainContent"
                image={_getImageFromPost(post)}
                title={post.title}
                text={post.content}
                loading={false}
                slideId="mainContent"
                slideIndex={currentSlideIndex}
                slideCount={slideIds.length}
              // ads={false}
              />
            }

            <div className="grid grid-cols-12 gap-4">
              {currentSlideIndex >= 1 &&
                <button
                  disabled={isLoading || isWaiting}
                  onClick={loadPreviousSlide}
                  className="col-span-3 bg-gray-500 hover:bg-gray-700 text-white font-bold text-2xl py-6 px-6 rounded inline-flex items-center w-full justify-center my-4 hover:shadow-xl shadow-md disabled:opacity-50 transition">
                  <svg className="mr-2" width="24" height="30" viewBox="0 0 24 24" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M1.02698 11.9929L5.26242 16.2426L6.67902 14.8308L4.85766 13.0033L22.9731 13.0012L22.9728 11.0012L4.85309 11.0033L6.6886 9.17398L5.27677 7.75739L1.02698 11.9929Z"
                      fill="currentColor" /></svg>
                </button>
              }
              <button
                className="col-span-9 bg-indigo-900 hover:bg-blue-700 text-white font-bold text-2xl py-6 px-6 rounded inline-flex items-center w-full justify-center my-4 hover:shadow-xl shadow-md disabled:opacity-50 transition"
                disabled={isLoading || isWaiting}
                onClick={loadNextSlide}>
                {(isWaiting || isLoading) && <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 32 32">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                }
                Next
                <svg className="ml-2 pt-1" width="32" height="36" viewBox="0 0 24 24" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M23.0677 11.9929L18.818 7.75739L17.4061 9.17398L19.2415 11.0032L0.932469 11.0012L0.932251 13.0012L19.2369 13.0032L17.4155 14.8308L18.8321 16.2426L23.0677 11.9929Z"
                    fill="currentColor" /></svg>
              </button>
            </div>

            <div>
              <div className="grid grid-cols-1 md:grid-cols-2 my-4">
                <Ad slotId="small_3" divId={`post1`} />
                <Ad slotId="small_4" divId={`post2`} />
              </div>
            </div>

          </div>

          <div className="lg:col-span-3 xl:col-span-6">
            <div className="grid grid-cols-12 lg:sticky lg:top-[90px]">
              <aside className="col-span-12 xl:col-span-6">
                {isMobile === false && <Ad slotId="mpu_haut" />}
              </aside>
              <aside className="col-span-12 xl:col-span-6">
                {isMobile === false && <Ad slotId="mpu_bas" />}
              </aside>
            </div>
          </div>

        </div>

      </article >

      <LazyLoad once>
        <Callback onMount={() => adStore.enableOutbrain()} />
      </LazyLoad>
    </div >
  )
}
export default SlidePage
