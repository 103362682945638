import { FunctionComponent } from "react"

interface SlidePlaceholderProps {}

export const SlideTextPlaceholder: FunctionComponent<SlidePlaceholderProps> = () => {
  return (
    <div className="w-full flex items-center flex-col">
      <div className="flex bg-white w-full">
        <div className="flex flex-col justify-between w-full">
          <div data-placeholder className="mb-2 h-20 w-full overflow-hidden relative bg-gray-200"></div>
        </div>
      </div>
    </div>
  )
}


export const SlideImagePlaceholder: FunctionComponent<SlidePlaceholderProps> = () => {
  return (
    <div className="w-full flex items-center flex-col">
      <div className="flex bg-white w-full">
        <div className="flex flex-col justify-between w-full">
          <div data-placeholder className="mb-2 h-96 w-full overflow-hidden relative bg-gray-200"></div>
        </div>
      </div>
    </div>
  )
}
