import { FunctionComponent, useEffect } from "react"

interface CallbackProps {
    /** Html text */
    onMount(): void
}

/** simple component to call a function when loaded (usefull because lazy load component doesn't have a callback function) */
const Callback: FunctionComponent<CallbackProps> = ({onMount}) => {
    
    useEffect(() => {
        onMount()
    }, [])

    return (
        <></>
    )
}
export default Callback
