import { useRouter } from "next/router";
import { FunctionComponent } from "react"
import { convertLinks } from "../lib/cms"

interface RichTextProps {
    /** Html text */
    text: string
}

const RichText: FunctionComponent<RichTextProps> = ({ text }) => {
    const router = useRouter()

    // handle click on links
    const handleAnchorClick = (e:any) => {
        const targetLink = e.target.closest('a');
        if (!targetLink) return;
        e.preventDefault();
        router.push(new URL(targetLink.href).pathname);
    };

    const transformedText = convertLinks(text)
    return (
        <div className="cms-editor text-justify"
            onClick={handleAnchorClick}
            onKeyPress={handleAnchorClick}
            dangerouslySetInnerHTML={{
                __html: transformedText
            }}
        ></div>
    )
}
export default RichText
