import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { mutate } from "swr";
import { useStore } from "../store";
import { getGalleryItem } from "./api";

export function useOption(option: string) {
    const [activated, setActivated] = useState(false);
    const router = useRouter()
    useEffect(() => {
        const params = new URLSearchParams(window?.location?.search)
        if (params.get(option) !== "false" && params.get(option) !== "0") {
            setActivated(true)
        }
    }, [router.query[option], router.isReady])

    return activated;
}


export function useSlidePrefetch(nextSlideId: string | null) {
    useEffect(() => {
        // preftech next slide
        if (nextSlideId && nextSlideId !== 'last') {
            // console.log("prefetch", nextSlideId)
            setTimeout(() => {
                mutate(["slide", nextSlideId], () => getGalleryItem(nextSlideId), false)
            }, 1)
        }
    }, [nextSlideId])
}

export function useRefreshAd(ids: string[]|null, dependency:any[]) {
    const { adStore } = useStore()
    useEffect(() => {
        console.log("refresh ", ids)
        if(ids)
            adStore.refreshAds(ids)
    }, dependency)
}

